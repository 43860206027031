<template>
  <div class="container relative" id="initiativ-bewerben">
    <BackgroundAnimation
      class="absolute bottom-16 left-1/2 -z-10 -ml-[calc(50vw-5rem)] xs:-ml-[calc(50vw-10rem)] lg:-ml-[calc(50vw-15rem)] xl:-ml-[calc(50vw-25rem)] 2xl:-ml-[calc(50vw-30rem)] 3xl:-ml-[30rem]"
    ></BackgroundAnimation>
    <h2 class="whitespace-nowrap text-vw-display-small">Be a smart part</h2>
    <div
      class="mt-vw-7-to-16 grid grid-cols-12 sm:w-11/12 md:w-10/12 lg:mt-vw-4-to-16 lg:w-full lg:gap-x-5"
    >
      <h3
        class="col-span-9 text-balance text-vw-headline-level-2 lg:col-span-3"
      >
        Wir freuen uns auf Dich als Teil unseres Teams
      </h3>
      <div class="col-span-3 flex justify-center lg:order-last lg:col-span-2">
        <IconComponent class="size-vw-10-to-20 text-blue" icon="rocket" />
      </div>
      <div
        class="col-span-12 mt-vw-4-to-16 pb-40 lg:col-span-5 lg:col-start-6 lg:mt-0"
      >
        <p class="max-w-prose">
          Du findest spannend, was wir tun und möchtest unser Team mit Deiner
          »smartness« bereichern?<br />Dann freuen wir uns auf Deine
          aussagekräftige Bewerbung.
        </p>
        <NuxtLink
          class="mt-vw-12-to-16 block rounded-full focus:outline-none focus-visible:outline-current"
          to="mailto:info@smartsquare.de?subject=Initiativ bewerben"
          external
          ><AppButton large class="relative w-full justify-between"
            >Initiativ bewerben<IconComponent icon="email" /></AppButton
        ></NuxtLink>
        <p class="mt-vw-6-to-8 rounded bg-white/30 p-vw-4-to-7 md:px-vw-7-to-8">
          Bitte hänge deiner Bewerbung einen Lebenslauf und alle relevanten
          Zeugnisse als PDF-Datei an.
        </p>
      </div>
    </div>
  </div>
</template>
