<template>
  <NuxtLink
    class="group focus:outline-none focus-visible:-outline-offset-2 focus-visible:outline-current"
    :to="to"
    ><AppHeaderNavButton :class="buttonClass"
      ><span
        class="flex items-center gap-vw-5-to-6 text-left lg:gap-vw-4-to-4.5"
        ><IconComponent
          class="size-vw-6-to-7 flex-none lg:size-vw-5-to-6"
          :icon="icon" />
        <slot></slot></span
      ><IconComponent
        class="size-vw-6-to-7 flex-none transition-transform group-hover:translate-x-1 lg:size-vw-5-to-6"
        icon="circle-arrow-right" /></AppHeaderNavButton
  ></NuxtLink>
</template>
<script lang="ts" setup>
defineProps<{
  to: string
  icon: string
  buttonClass?: string
}>()
</script>
