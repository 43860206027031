<template>
  <div ref="swiperWrapper" class="bg-dark-blue pt-vw-7-to-32">
    <div
      class="container flex items-center justify-between gap-vw-4-to-5 text-white"
    >
      <h2 class="text-vw-headline-level-2">Weitere Kategorien</h2>
      <div class="flex gap-2">
        <button
          class="rounded-full transition-colors hover:text-green focus:outline-none focus-visible:outline-current"
          ref="swiperButtonPrev"
          type="button"
        >
          <IconComponent
            class="size-vw-8-to-14"
            icon="circle-arrow-left-large"
          />
        </button>
        <button
          class="rounded-full transition-colors hover:text-green focus:outline-none focus-visible:outline-current"
          ref="swiperButtonNext"
          type="button"
        >
          <IconComponent
            class="size-vw-8-to-14"
            icon="circle-arrow-right-large"
          />
        </button>
      </div>
    </div>
    <div class="mt-vw-15-to-20">
      <nav aria-label="Weitere Kategorien Navigation">
        <swiper-container init="false">
          <template v-for="item in useAppConfig().navigationItems">
            <template v-if="item.items" v-for="subItem in item.items">
              <swiper-slide
                v-if="route.path !== subItem.to"
                class="h-vw-20-to-48 w-vw-52-to-100"
              >
                <NuxtLink
                  class="group block h-full rounded-full focus:outline-none focus-visible:-outline-offset-4 focus-visible:outline-current"
                  :to="subItem.to"
                  ><AppPill
                    class="!text-vw-in-pill-large h-full justify-center !gap-vw-3.5-to-8"
                    :class="{
                      'bg-white': subItem.bgColor === 'white',
                      'bg-green': subItem.bgColor === 'green',
                      'bg-blue': subItem.bgColor === 'blue',
                      'text-dark-blue': subItem.textColor === 'dark-blue',
                      'text-white': subItem.textColor === 'white',
                    }"
                    ><span v-html="subItem.titleFormatted"></span
                    ><IconComponent
                      class="size-vw-6-to-14 flex-none transition-transform group-hover:translate-x-1"
                      icon="circle-arrow-right-filled"
                  /></AppPill> </NuxtLink
              ></swiper-slide>
            </template>
            <template v-else>
              <swiper-slide
                v-if="route.path !== item.to"
                class="h-vw-20-to-48 w-vw-52-to-100"
              >
                <NuxtLink
                  class="group block h-full rounded-full focus:outline-none focus-visible:-outline-offset-4 focus-visible:outline-current"
                  :to="item.to"
                  ><AppPill
                    class="!text-vw-in-pill-large h-full justify-center !gap-vw-3.5-to-8"
                    :class="{
                      'bg-white': item.bgColor === 'white',
                      'bg-green': item.bgColor === 'green',
                      'bg-blue': item.bgColor === 'blue',
                      'text-dark-blue': item.textColor === 'dark-blue',
                      'text-white': item.textColor === 'white',
                    }"
                    ><span v-html="item.titleFormatted ?? item.title"></span
                    ><IconComponent
                      class="size-vw-6-to-14 flex-none transition-transform group-hover:translate-x-1"
                      icon="circle-arrow-right-filled"
                  /></AppPill> </NuxtLink
              ></swiper-slide>
            </template>
          </template>
        </swiper-container>
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core'
const route = useRoute()
const swiperWrapper = ref()
const swiperButtonPrev = ref()
const swiperButtonNext = ref()

let swiperEl: any = null

onMounted(() => {
  initHistorySwiper()
  window.addEventListener('resize', initHistorySwiper)
})

const initHistorySwiper = useDebounceFn(() => {
  swiperEl = swiperWrapper.value?.querySelector('swiper-container')
  if (swiperEl) {
    const swiperParams = {
      slidesPerView: 'auto',
      loop: true,
      centeredSlides: true,
      navigation: {
        prevEl: swiperButtonPrev.value,
        nextEl: swiperButtonNext.value,
        disabledClass: 'opacity-50 pointer-events-none',
      },
    }
    Object.assign(swiperEl, swiperParams)
    swiperEl.initialize()
  }
}, 100)
</script>
