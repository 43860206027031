<template>
  <div class="container relative" id="kontakt">
    <BackgroundAnimation
      class="absolute bottom-16 left-1/2 -z-10 -ml-[calc(50vw-5rem)] xs:-ml-[calc(50vw-10rem)] lg:-ml-[calc(50vw-15rem)] xl:-ml-[calc(50vw-25rem)] 2xl:-ml-[calc(50vw-30rem)] 3xl:-ml-[30rem]"
    ></BackgroundAnimation>
    <h2 class="whitespace-nowrap text-vw-display">Let’s connect</h2>
    <div
      v-if="!state.sent"
      class="mt-vw-7-to-16 grid grid-cols-12 sm:w-11/12 md:w-10/12 lg:mt-vw-4-to-16 lg:w-full lg:gap-x-5"
    >
      <h3
        class="col-span-9 text-balance text-vw-headline-level-2 lg:col-span-3"
      >
        Besprechen Sie mit uns Ihre Möglichkeiten
      </h3>
      <div class="col-span-3 flex justify-center lg:order-last lg:col-span-2">
        <IconComponent class="size-vw-10-to-20 text-blue" icon="rocket" />
      </div>
      <p
        class="col-span-12 mt-vw-4-to-16 max-w-prose lg:col-span-5 lg:col-start-6 lg:mt-0"
      >
        Egal, ob Sie bereits konkrete Vorstellungen haben, oder ob Sie ein
        bestimmte Anforderung haben, die gelöst werden soll. Besprechen Sie mit
        uns Ihre Möglichkeiten und starten wir ein gemeinsames Projekt.
      </p>
    </div>
    <div class="pb-40" :class="{ 'mt-vw-12-to-28': !state.sent }">
      <form
        v-if="!state.sent"
        class="group/form sm:w-11/12 md:w-10/12 lg:grid lg:w-full lg:grid-cols-12 lg:gap-x-5"
        :action="url"
        method="post"
        @submit="submitForm"
      >
        <div class="lg:col-span-12 lg:group-has-[:checked]/form:col-span-3">
          <p class="group-has-[:checked]/form:hidden max-lg:text-right">
            Worüber wollen wir sprechen?
          </p>
          <p class="hidden group-has-[:checked]/form:block max-lg:text-right">
            Ihr gewähltes Thema:
          </p>
          <fieldset
            class="mt-vw-4-to-6 grid gap-vw-4-to-5 lg:grid-cols-2 lg:group-has-[:checked]/form:!flex xl:flex"
          >
            <template v-for="topic in topics">
              <label
                v-if="topic.checked || topics.every((topic) => !topic.checked)"
                :for="topic.id"
                ><AppButton
                  large
                  class="group relative w-full justify-between has-[:checked]:!bg-dark-blue has-[:checked]:!text-white lg:has-[:checked]:whitespace-nowrap xl:h-full"
                  ><span class="flex items-center justify-center gap-4"
                    ><IconComponent
                      class="hidden group-has-[:checked]:block"
                      icon="circle-arrow-left"
                    />{{ topic.label }}</span
                  >
                  <input
                    type="checkbox"
                    v-model="topic.checked"
                    class="absolute inset-0 cursor-pointer appearance-none rounded-full focus:outline-none focus-visible:outline-dark-blue"
                    name="topic"
                    :value="topic.label"
                    :id="topic.id" /><IconComponent
                    class="group-has-[:checked]:hidden"
                    icon="circle-arrow-right-filled" /></AppButton
              ></label>
            </template>
          </fieldset>
        </div>
        <div class="lg:col-span-5 lg:col-start-6">
          <template v-for="topic in topics">
            <template
              v-if="topic.checked"
              v-for="(question, index) in topic.questions"
            >
              <div
                v-if="
                  index === 0 ||
                  (topic.questions && topic.questions[index - 1].value !== '')
                "
              >
                <fieldset
                  class="flex flex-col gap-vw-6-to-8 px-8 pb-8 pt-6 transition-all"
                  :class="{
                    'bg-white/30 shadow-xl': question.value === '',
                    'bg-white/50': question.value !== '',
                    'mt-vw-8-to-16': question.value === '' || index === 0,
                    'mt-vw-2-to-4': question.value !== '' && index !== 0,
                  }"
                >
                  <div class="flex flex-col gap-4">
                    <label
                      class="transition-all"
                      :class="{
                        'text-vw-headline-level-2': question.value === '',
                      }"
                      :for="question.id"
                      >{{ question.label }}</label
                    >
                    <select
                      name="questions[]"
                      :id="question.id"
                      v-model="question.value"
                      class="form-select w-full truncate rounded-full !border-0 py-vw-3-to-4.5 pl-vw-6-to-9 pr-vw-12-to-15 text-vw-button-large !ring-0 transition-colors focus:!outline focus:!outline-current focus-visible:!outline focus-visible:!outline-current"
                      :class="{
                        'bg-grey': question.value !== '',
                      }"
                    >
                      <option value="" selected disabled>Bitte wählen</option>
                      <option
                        v-for="answer in question.answers"
                        :value="
                          JSON.stringify({
                            question: question.label,
                            answer: answer.label,
                          })
                        "
                      >
                        {{ answer.label }}
                      </option>
                    </select>
                  </div>
                </fieldset>
                <p class="mt-4 max-w-prose" v-if="question.hint">
                  {{ question.hint }}
                </p>
              </div>
            </template>
          </template>
          <template v-for="(topic, index) in topics">
            <Transition>
              <fieldset
                v-if="
                  (topic.checked &&
                    topic.questions?.every(
                      (question) => question.value !== ''
                    )) ||
                  (topics[3].checked && index === 0)
                "
                class="mt-vw-8-to-16 flex flex-col gap-vw-6-to-8 bg-white/30 px-8 pb-8 pt-6 shadow-xl"
              >
                <div class="flex flex-col gap-4">
                  <label class="text-vw-headline-level-2" for="contactName"
                    >Mein Name ist:</label
                  >
                  <input
                    type="text"
                    class="w-full rounded-full px-vw-6-to-9 py-vw-3-to-4.5 text-vw-button-large focus:outline-none focus-visible:outline-current"
                    id="contactName"
                    name="name"
                    placeholder="Vorname Name"
                    required
                  />
                </div>
                <div class="flex flex-col gap-4">
                  <label class="text-vw-headline-level-2" for="contactEmail"
                    >Ich bin zu erreichen unter der E-Mail-Adresse:</label
                  >
                  <input
                    type="text"
                    class="w-full rounded-full px-vw-6-to-9 py-vw-3-to-4.5 text-vw-button-large focus:outline-none focus-visible:outline-current"
                    id="contactEmail"
                    name="email"
                    placeholder="name@musterfirma.com"
                    required
                  />
                </div>
              </fieldset>
            </Transition>
          </template>
          <template v-for="topic in topics">
            <Transition>
              <p
                v-if="topic.checked"
                class="mt-6 flex items-center gap-vw-3-to-3.5"
              >
                {{
                  topic.questions
                    ? topic.questions.filter(
                        (question) => question.value !== ''
                      ).length + 1
                    : 1
                }}/{{ topic.questions ? topic.questions.length + 1 : 1 }}
              </p>
            </Transition>
          </template>
          <template v-for="(topic, index) in topics">
            <Transition>
              <button
                v-if="
                  (topic.checked &&
                    topic.questions?.every(
                      (question) => question.value !== ''
                    )) ||
                  (topics[3].checked && index === 0)
                "
                class="mt-vw-12-to-14 w-full rounded-full focus:outline-none focus-visible:outline-current"
              >
                <AppButton class="w-full justify-between" large inverted
                  >Anfrage senden<IconComponent icon="email"
                /></AppButton>
              </button>
            </Transition>
          </template>
        </div>
      </form>
      <div
        v-if="state.sent"
        class="mt-vw-7-to-16 grid grid-cols-12 sm:w-11/12 md:w-10/12 lg:mt-vw-4-to-16 lg:w-full lg:gap-x-5"
      >
        <h3
          class="col-span-9 text-balance text-vw-headline-level-2 lg:col-span-3"
          :class="{
            'text-warning': !state.success,
            'text-blue': state.success,
          }"
        >
          <span v-if="state.success"> Vielen Dank für Ihre Anfrage.</span>
          <span v-else>Das hat leider nicht geklappt.</span>
        </h3>
        <div class="col-span-3 flex justify-center lg:order-last lg:col-span-2">
          <IconComponent
            v-if="state.success"
            class="size-vw-10-to-20 text-blue"
            icon="checkmark"
          />
          <IconComponent
            v-else
            class="size-vw-10-to-20 text-warning"
            icon="bug"
          />
        </div>
        <div
          class="col-span-12 mt-vw-4-to-16 lg:col-span-5 lg:col-start-6 lg:mt-0"
        >
          <p class="max-w-prose">
            <span v-if="state.success">
              Wir haben Ihre Nachricht erhalten und setzen uns kurzfristig mit
              Ihnen per E-Mail in Verbidnung.
            </span>
            <span v-else>
              Ihre Nachricht konnte uns leider nicht zugestellt werden, da der
              Server unseres E-Mail-Anbieters nicht antwortet. Bitte versuchen
              Sie es erneut.
            </span>
          </p>
          <NuxtLinkAnchor
            v-if="state.success"
            class="mt-vw-12-to-16 block rounded-full focus:outline-none focus-visible:outline-current"
            href="#top"
            ><AppButton large class="relative w-full justify-between"
              >Zum Seitenanfang<IconComponent
                icon="circle-arrow-top-filled" /></AppButton
          ></NuxtLinkAnchor>
          <NuxtLink
            v-else
            @click="refresh"
            class="mt-vw-12-to-16 block rounded-full focus:outline-none focus-visible:outline-current"
            :to="{ hash: '#kontakt' }"
            ><AppButton large class="relative w-full justify-between"
              >Formular erneut ausfüllen<IconComponent
                icon="circle-arrow-right-filled" /></AppButton
          ></NuxtLink>
        </div>
      </div>
    </div>
    <div
      class="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-white/30"
      v-if="state.isLoading"
    >
      <LoadingSpinner class="size-vw-48-to-96 animate-spin" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { KnownRouteName } from 'ziggy-js'
import type { FormResponseData } from '~/types/generated'
import { Ziggy } from '~/ziggy'
const topics = ref([
  {
    label: 'Softwarelösungen',
    id: 'contactTopicSoftwareSolutions',
    checked: false,
    questions: [
      {
        label: 'Mein Augenmerk liegt auf:',
        id: 'softwareSolutionsAttention',
        value: '',
        answers: [
          {
            label: 'Kundendienst & Aftersales',
          },
          {
            label: 'Prozesse & Daten',
          },
          {
            label: 'Fernüberwachung & Monitoring',
          },
          {
            label: 'etwas anderem',
          },
        ],
      },
      {
        label: 'Zur Zeit möchte ich:',
        id: 'softwareSolutionsCurrentDesire',
        value: '',
        answers: [
          {
            label: 'mich unverbindlich informieren',
          },
          {
            label: 'Ideen und Informationen sammeln',
          },
          {
            label: 'ein konkretes Projekt vorbereiten',
          },
        ],
      },
      {
        label: 'Dann möchte ich mit der Umsetzung an den Markt gehen:',
        id: 'digitalBusinessModelsGoToMarket',
        value: '',
        answers: [
          {
            label: 'möglichst zeitnah',
          },
          {
            label: 'innerhalb der nächsten 3 - 6 Monate',
          },
          {
            label: 'mittel- bis langfristig',
          },
        ],
      },
      {
        label: 'Unser Budget beträgt im ersten Schritt:',
        id: 'digitalBusinessModelsBudget',
        value: '',
        answers: [
          {
            label: 'weniger als 50k',
          },
          {
            label: '51 - 100k',
          },
          {
            label: 'mehr als 100k',
          },
        ],
        hint: 'Die Frage nach dem Budget hilft uns einzuschätzen, wie individuell wir Ihre Lösung planen und umsetzen dürfen.',
      },
    ],
  },
  {
    label: 'Servicebuddy-App',
    id: 'contactTopicServicebuddyApp',
    checked: false,
    questions: [
      {
        label: 'Bisher interessiert mich am Servicebuddy vor allem:',
        id: 'servicebuddyInterest',
        value: '',
        answers: [
          {
            label: 'die Service-App für meine Anwender',
          },
          {
            label: 'das Hersteller-Portal für meinen Kundendienst',
          },
          {
            label: 'die IoT-Funktonalität',
          },
          {
            label: 'eine Demo-Version',
          },
          {
            label: 'etwas anderes',
          },
        ],
      },
      {
        label: 'Zur Zeit möchte ich:',
        id: 'servicebuddyCurrentDesire',
        value: '',
        answers: [
          {
            label: 'mich unverbindlich informieren',
          },
          {
            label: 'Ideen und Informationen sammeln',
          },
          {
            label: 'ein konkretes Projekt vorbereiten',
          },
        ],
      },
      {
        label: 'Dann möchte ich mit der Umsetzung an den Markt gehen:',
        id: 'digitalBusinessModelsGoToMarket',
        value: '',
        answers: [
          {
            label: 'möglichst zeitnah',
          },
          {
            label: 'innerhalb der nächsten 3 - 6 Monate',
          },
          {
            label: 'mittel- bis langfristig',
          },
        ],
      },
      {
        label: 'Unser Budget beträgt im ersten Schritt:',
        id: 'digitalBusinessModelsBudget',
        value: '',
        answers: [
          {
            label: 'weniger als 50k',
          },
          {
            label: '51 - 100k',
          },
          {
            label: 'mehr als 100k',
          },
        ],
        hint: 'Die Frage nach dem Budget hilft uns einzuschätzen, wie individuell wir Ihre Lösung planen und umsetzen dürfen.',
      },
    ],
  },
  {
    label: 'Digitale Geschäftsmodelle',
    id: 'contactTopicDigitalBusinessModels',
    checked: false,
    questions: [
      {
        label: 'Zu digitalen Geschäftsmodellen für mein Unternehmen habe ich:',
        id: 'digitalBusinessModelsApproach',
        value: '',
        answers: [
          {
            label: 'konkrete Vorstellungen',
          },
          {
            label: 'erste Ideen',
          },
          {
            label: 'noch keinen Ansatz',
          },
        ],
      },
      {
        label: 'Ich bin auf der Suche nach:',
        id: 'digitalBusinessModelsSearchingFor',
        value: '',
        answers: [
          {
            label: 'Impulsen und Beispielen',
          },
          {
            label: 'einer konkreten Vorgehensweise',
          },
        ],
      },
      {
        label: 'Ich bin:',
        id: 'digitalBusinessModelsIAm',
        value: '',
        answers: [
          {
            label: 'Maschinen- und Anlagenbauer',
          },
          {
            label: 'fertigendes Unternehmen',
          },
          {
            label: 'andere Branche',
          },
        ],
      },
      {
        label: 'Dann möchte ich mit der Umsetzung an den Markt gehen:',
        id: 'digitalBusinessModelsGoToMarket',
        value: '',
        answers: [
          {
            label: 'möglichst zeitnah',
          },
          {
            label: 'innerhalb der nächsten 3 - 6 Monate',
          },
          {
            label: 'mittel- bis langfristig',
          },
        ],
      },
      {
        label: 'Unser Budget beträgt im ersten Schritt:',
        id: 'digitalBusinessModelsBudget',
        value: '',
        answers: [
          {
            label: 'weniger als 50k',
          },
          {
            label: '51 - 100k',
          },
          {
            label: 'mehr als 100k',
          },
        ],
        hint: 'Die Frage nach dem Budget hilft uns einzuschätzen, wie individuell wir Ihre Lösung planen und umsetzen dürfen.',
      },
    ],
  },
  {
    label: 'Anderes',
    id: 'contactTopicOther',
    checked: false,
  },
])

const state = reactive<{
  success: boolean
  isLoading: boolean
  sent: boolean
}>({
  success: false,
  isLoading: false,
  sent: false,
})
const app = useNuxtApp()

onMounted(() => {
  app.$bus.$on(
    'letsConnect',
    (options: { topicId: string; answer: number }) => {
      topics.value.forEach((topic) => {
        topic.checked = false
        topic.questions?.forEach((question) => {
          question.value = ''
        })
      })
      state.success = false
      state.isLoading = false
      state.sent = false
      const topic = topics.value.filter(
        (topic) => topic.id === options.topicId
      )[0]
      topic.checked = true
      if (topic.questions && options.answer !== undefined) {
        topic.questions[0].value = JSON.stringify({
          question: topic.questions[0].label,
          answer: topic.questions[0].answers[options.answer].label,
        })
      }
    }
  )
})

const url =
  (useRuntimeConfig().public.apiBaseUrl as string) +
  generateApiRoute('forms.contact' as KnownRouteName, Ziggy)

const submitForm = async (event: Event) => {
  event.preventDefault()
  state.isLoading = true
  const form = event.target as HTMLFormElement
  const formData = new FormData(form)
  try {
    const res = await $fetch<FormResponseData>(
      generateApiRoute('forms.contact' as KnownRouteName, Ziggy)!,
      {
        method: 'POST',
        body: formData,
        baseURL: useRuntimeConfig().public.apiBaseUrl as string,
      }
    )
    state.sent = true
    state.isLoading = false
    state.success = res.form || false
  } catch (err) {
    console.error(err)
    state.sent = true
    state.isLoading = false
    state.success = false
  }
}

function refresh() {
  location.reload()
}
</script>
