<template>
  <div id="top" class="flex min-h-svh flex-col">
    <PreviewBanner />
    <AppHeaderModule
      :reactive-navigation-disabled="!!route.meta.disableStickyHeader"
      :has-hero="!!route.meta.hasHero"
    />
    <main class="flex grow flex-col">
      <div class="grow">
        <slot></slot>
      </div>
      <LetsConnectTeam v-if="store.isTeam" />
      <LetsConnectJobs v-else-if="store.isJobs" />
      <LetsConnectDefault v-else-if="!store.isIndependent" />
      <FurtherCategories />
    </main>
    <AppFooter />
  </div>
</template>

<script lang="ts" setup>
import { store } from '~/store'
import routeMetaData from '~/config/routeMetaData'
const router = useRouter()
const route = useRoute()

router.afterEach((to, _from) => {
  setRouteMetaData(to)
})

setRouteMetaData(router.currentRoute.value)

function setRouteMetaData(to: any) {
  store.isTeam =
    routeMetaData[to.name?.toString().split('___')[0] || '']?.isTeam
  store.isJobs =
    routeMetaData[to.name?.toString().split('___')[0] || '']?.isJobs
  store.isIndependent =
    routeMetaData[to.name?.toString().split('___')[0] || '']?.isIndependent
}

if (import.meta.prerender && route.path === '/') {
  const errorImg = {
    src: '/img/404/404.png',
    width: 1360,
    height: 790,
  }
  const $img = useImage()
  Object.values($img.options.screens).forEach((width) => {
    $img.getImage(errorImg.src, {
      modifiers: {
        width,
        height: Math.round(width * (errorImg.height / errorImg.width)),
        format: 'webp',
      },
    })
  })
}
</script>
