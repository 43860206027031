<template>
  <ul class="flex flex-col gap-vw-2-to-3 text-vw-headline-level-2 text-white">
    <li v-for="item in useAppConfig().navigationItems">
      <Disclosure v-if="item.items" as="div" v-slot="{ open }">
        <DisclosureButton
          class="w-full focus:outline-none focus-visible:-outline-offset-2 focus-visible:outline-current"
        >
          <AppHeaderNavButton
            :class="{
              'bg-white/30': !open,
              'bg-blue': open,
            }"
          >
            <span class="flex items-center gap-vw-5-to-6 text-left">
              <IconComponent
                class="size-vw-6-to-7 flex-none"
                :icon="item.icon"
              />
              {{ item.title }}</span
            >
            <IconComponent
              class="size-vw-6-to-7 flex-none"
              icon="circle-minus"
              :class="{
                hidden: !open,
              }" />
            <IconComponent
              class="size-vw-6-to-7 flex-none"
              icon="circle-plus"
              :class="{
                hidden: open,
              }"
          /></AppHeaderNavButton>
        </DisclosureButton>
        <DisclosurePanel
          static
          class="grid overflow-hidden transition-grid-template-rows"
          :class="open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'"
        >
          <div
            class="min-h-0 transition-visibility"
            :class="{
              visible: open,
              invisible: !open,
            }"
          >
            <ul class="mb-vw-8-to-10 mt-vw-2-to-3 flex flex-col gap-vw-2-to-3">
              <li v-for="subItem in item.items">
                <AppHeaderNavLink
                  buttonClass="bg-blue/30"
                  :to="subItem.to"
                  @click="close"
                  :icon="subItem.icon"
                  >{{ subItem.title }}</AppHeaderNavLink
                >
              </li>
            </ul>
          </div>
        </DisclosurePanel>
      </Disclosure>
      <AppHeaderNavLink
        v-else
        buttonClass="bg-white/30"
        :to="item.to"
        @click="close"
        :icon="item.icon"
        >{{ item.title }}</AppHeaderNavLink
      >
    </li>
  </ul>
</template>

<script lang="ts" setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  provideUseId,
} from '@headlessui/vue'

provideUseId(() => useId())
defineProps<{
  close: (...args: any[]) => void
}>()
</script>
