<template>
  <ReactiveNavigation
    class="pt-vw-24-to-32"
    :class="{
      'lg:pt-0': props.hasHero,
    }"
    v-slot="{ headerMode }"
    :scroll-up-velocity="2000"
  >
    <div
      class="fixed left-0 top-0 z-20 w-full transition-all duration-500"
      :class="{
        'backdrop-blur':
          headerMode === 'pinned' && !props.reactiveNavigationDisabled,
        '!absolute':
          headerMode === 'visible' ||
          headerMode === 'hidden' ||
          reactiveNavigationDisabled,
        '-translate-y-full opacity-10':
          headerMode === 'hidden' && !reactiveNavigationDisabled,
      }"
    >
      <header class="container h-vw-24-to-32">
        <nav class="flex justify-between gap-vw-4-to-5 py-vw-6-to-7">
          <component
            class="inline-flex"
            :is="route.path === '/' ? 'h1' : 'div'"
          >
            <NuxtLink
              class="inline-flex focus:outline-none focus-visible:outline-current"
              to="/"
            >
              <AppLogo class="w-vw-25-to-40"></AppLogo>
            </NuxtLink>
          </component>
          <ClientOnly>
            <Popover
              v-slot="{ open }"
              class="has-[.no-scroll]:scrollbar-gutter-stable relative w-full has-[.no-scroll]:overflow-auto lg:hidden"
            >
              <PopoverButton
                :title="open ? 'Menü schließen' : 'Menü öffnen'"
                class="absolute right-1 top-1 z-20 flex size-8 items-center justify-center focus:outline-none"
                :class="{
                  'no-scroll text-grey': open,
                  'text-dark-blue': !open,
                }"
              >
                <AppHeaderBurgerIcon :open="open" />
              </PopoverButton>
              <PopoverPanel
                static
                v-slot="{ close }"
                class="bg-menu-mobile container fixed left-0 top-0 z-10 h-dvh w-screen !max-w-full bg-dark-blue bg-[length:100%_auto] bg-bottom bg-no-repeat pt-vw-32-to-36"
                :class="{
                  hidden: !open,
                }"
              >
                <NuxtLink
                  class="absolute left-vw-7-to-10 top-vw-7-to-8 inline-flex"
                  tabindex="-1"
                  to="/"
                  @click="close"
                  aria-hidden="true"
                >
                  <AppLogo class="w-vw-25-to-40 text-grey"></AppLogo>
                </NuxtLink>
                <div
                  class="scrollbar-gutter-stable max-h-full overflow-auto overscroll-contain focus:outline-none focus-visible:outline-white"
                >
                  <AppHeaderMobileNavigation
                    :close="close"
                  ></AppHeaderMobileNavigation>
                  <AppHeaderMobileSocialMedia></AppHeaderMobileSocialMedia>
                </div>
              </PopoverPanel>
            </Popover>
          </ClientOnly>
          <div class="flex items-center gap-vw-11-to-12 max-lg:hidden">
            <AppHeaderDesktopNavigation></AppHeaderDesktopNavigation>
            <AppHeaderDesktopSocialMedia></AppHeaderDesktopSocialMedia>
          </div>
        </nav>
      </header>
    </div>
  </ReactiveNavigation>
</template>

<script lang="ts" setup>
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  provideUseId,
} from '@headlessui/vue'

const props = defineProps<{
  reactiveNavigationDisabled: boolean
  hasHero: boolean
}>()

provideUseId(() => useId())

const route = useRoute()
</script>
