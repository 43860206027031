<template>
  <ul
    class="my-auto grid auto-cols-auto grid-flow-col grid-rows-2 gap-x-vw-9-to-10 gap-y-vw-1.5-to-2 text-vw-desktop-nav"
  >
    <li class="relative" v-for="item in useAppConfig().navigationItems">
      <Popover v-if="item.items" v-slot="{ open }">
        <PopoverButton
          class="flex items-center gap-vw-1.5-to-2 whitespace-nowrap hover:text-blue focus:outline-none"
          :class="{
            'absolute z-20 text-blue':
              open || route.path.includes(item.items[0].to.split('/')[1]),
          }"
          >{{ item.titleDesktop
          }}<IconComponent
            class="size-vw-2.5-to-3 flex-none transition-transform"
            :class="{
              'rotate-45': open,
            }"
            icon="circle-plus"
          />
        </PopoverButton>
        <span
          class="invisible flex h-0 items-center gap-vw-1.5-to-2 overflow-hidden whitespace-nowrap"
          aria-hidden="true"
          >{{ item.titleDesktop
          }}<IconComponent
            class="size-vw-2.5-to-3 flex-none"
            icon="circle-plus"
          />
        </span>
        <PopoverPanel
          class="bg-menu-desktop absolute -left-vw-6-to-8 -top-vw-14-to-16 z-10 w-[150%] rounded bg-dark-blue bg-[length:100%_auto] bg-bottom bg-no-repeat px-vw-6-to-8 pb-vw-6-to-8 pt-vw-24-to-28 text-white"
          static
          v-slot="{ close }"
          :class="{
            hidden: !open,
          }"
        >
          <ul class="flex flex-col gap-vw-1.5-to-2">
            <li v-for="subItem in item.items">
              <AppHeaderNavLink
                buttonClass="bg-blue/30"
                :to="subItem.to"
                @click="close"
                :icon="subItem.icon"
                ><span v-html="subItem.titleFormatted"></span
              ></AppHeaderNavLink>
            </li>
          </ul>
        </PopoverPanel>
      </Popover>
      <NuxtLink
        v-else
        class="flex items-center gap-vw-1.5-to-2 whitespace-nowrap transition-colors hover:text-blue focus:outline-none focus-visible:outline-current"
        :class="{
          'text-blue': route.path.includes(item.to),
        }"
        :to="item.to"
        >{{ item.titleDesktop ?? item.title
        }}<IconComponent
          class="size-vw-2.5-to-3 flex-none"
          icon="circle-arrow-right"
      /></NuxtLink>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  provideUseId,
} from '@headlessui/vue'

provideUseId(() => useId())

const route = useRoute()
</script>
