<template>
  <ul
    class="relative my-vw-12-to-14 flex items-center justify-end gap-vw-8-to-10 pr-vw-5-to-6 text-grey"
  >
    <template v-for="item in useAppConfig().socialMediaItems">
      <li v-if="!item.items">
        <NuxtLink
          class="inline-block focus:outline-none focus-visible:outline-current"
          :to="item.to"
          external
          target="_blank"
        >
          <IconComponent class="size-vw-8-to-9" :icon="item.icon" />
          <span class="sr-only">{{ item.title }}</span>
        </NuxtLink>
      </li>
      <li
        v-if="item.items"
        class="has-[.podcast-menu-open]:w-full has-[.podcast-menu-open]:flex-none"
      >
        <Disclosure as="div" v-slot="{ open }">
          <DisclosureButton
            :title="
              open
                ? 'Podcast Maschinensprache Menü schließen'
                : 'Podcast Maschinensprache Menü öffnen'
            "
            class="inline-block focus:outline-none focus-visible:outline-current"
            :class="{
              'podcast-menu-open absolute right-vw-5-to-6 mt-vw-4-to-5': open,
            }"
            ><IconComponent
              class="size-vw-8-to-9"
              icon="headset-plus"
              :class="{
                hidden: open,
              }"
            /><IconComponent
              class="size-vw-8-to-9 text-blue"
              icon="headset-minus"
              :class="{
                hidden: !open,
              }"
            />
          </DisclosureButton>
          <DisclosurePanel
            class="-mr-vw-5-to-6 -mt-vw-4-to-5"
            static
            :class="{
              hidden: !open,
            }"
            ><div
              class="rounded bg-white px-vw-4-to-5 pb-vw-8-to-9 pt-vw-4-to-5 text-vw-headline-level-2 text-blue"
            >
              <p class="whitespace-nowrap pr-vw-8-to-9">Podcast hören auf:</p>

              <ul
                class="mr-vw-1-to-2 mt-vw-9-to-10 flex items-center justify-between gap-vw-7-to-8"
              >
                <li v-for="subItem in item.items">
                  <a
                    class="inline-block focus:outline-none focus-visible:outline-current"
                    :href="subItem.to"
                    external
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconComponent
                      class="size-vw-8-to-9 text-black"
                      :icon="subItem.icon"
                    />

                    <span class="sr-only">{{ subItem.title }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </li>
    </template>
  </ul>
</template>

<script lang="ts" setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  provideUseId,
} from '@headlessui/vue'

provideUseId(() => useId())
</script>
