import { Popover } from '@headlessui/vue';
<template>
  <ul
    class="my-auto grid grid-cols-2 gap-x-vw-4-to-4.5 gap-y-vw-2.5-to-3 text-vw-desktop-nav"
  >
    <template v-for="item in useAppConfig().socialMediaItems">
      <li class="inline-flex" v-if="!item.items">
        <NuxtLink
          class="inline-block transition-colors hover:text-blue focus:outline-none focus-visible:outline-current"
          :to="item.to"
          external
          target="_blank"
        >
          <IconComponent class="size-vw-4-to-5" :icon="item.icon" />
          <span class="sr-only">{{ item.title }}</span>
        </NuxtLink>
      </li>
      <li class="relative inline-flex" v-if="item.items">
        <Popover class="inline-flex" as="div" v-slot="{ open }">
          <PopoverButton
            :title="
              open
                ? 'Podcast Maschinensprache Menü schließen'
                : 'Podcast Maschinensprache Menü öffnen'
            "
            class="inline-block hover:text-blue focus:outline-none"
            :class="{
              'absolute z-20': open,
            }"
            ><IconComponent
              class="size-vw-4-to-5"
              icon="headset-plus"
              :class="{
                hidden: open,
              }"
            /><IconComponent
              class="size-vw-4-to-5 text-blue"
              icon="headset-minus"
              :class="{
                hidden: !open,
              }"
            />
          </PopoverButton>
          <PopoverPanel
            class="absolute -right-vw-4-to-5 -top-vw-3-to-4 z-10"
            static
            :class="{
              hidden: !open,
            }"
            ><div
              class="rounded bg-white px-vw-4-to-5 pb-vw-4-to-5 pt-vw-3-to-4 text-blue"
            >
              <p class="whitespace-nowrap pr-vw-8-to-9">Podcast hören auf:</p>

              <ul
                class="mt-vw-5-to-6 flex items-center justify-between gap-x-vw-5-to-6"
              >
                <li v-for="subItem in item.items">
                  <a
                    class="group inline-block focus:outline-none focus-visible:outline-current"
                    :href="subItem.to"
                    external
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconComponent
                      class="size-vw-4-to-5 text-black group-hover:text-blue"
                      :icon="subItem.icon"
                    />

                    <span class="sr-only">{{ subItem.title }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </PopoverPanel>
        </Popover>
      </li>
    </template>
  </ul>
</template>

<script lang="ts" setup>
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  provideUseId,
} from '@headlessui/vue'

provideUseId(() => useId())
</script>
