<template>
  <ClientOnly v-if="isPreviewMode"
    ><div
      class="fixed right-0 top-0 z-50 w-full bg-green px-2 py-4 text-left text-sm leading-none text-white"
    >
      <b>Vorschaumodus</b>: Die Stellenanzeige ist für Besucher Ihrer Seite
      (noch) nicht sichtbar.
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
const isPreviewMode = useRoute().query.preview !== undefined
</script>
