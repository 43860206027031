<template>
  <footer class="bg-dark-blue pb-vw-8-to-16 pt-vw-20-to-25">
    <div
      class="container text-white lg:flex lg:justify-between lg:gap-vw-7-to-20"
    >
      <AppLogo class="w-vw-25-to-40 lg:my-auto"></AppLogo>
      <div class="lg:flex lg:gap-vw-7-to-20">
        <div class="mt-vw-8-to-10 flex gap-vw-7-to-20 lg:mt-0">
          <address>
            Smartsquare GmbH<br />Otto-Brenner-Str. 247<br />D-33604 Bielefeld
          </address>
          <div class="flex flex-col gap-1">
            <NuxtLink
              class="mr-auto inline-flex items-center gap-vw-1-to-3 transition-colors hover:text-green focus:outline-none focus-visible:outline-current"
              external
              to="mailto:info@smartsquare.de"
            >
              info@smartsquare.de<IconComponent
                icon="arrow-right"
                class="w-vw-2-to-3 flex-none"
              />
            </NuxtLink>
            <NuxtLink
              class="text-vw-headline-level-2 text-green focus:outline-none focus-visible:outline-current"
              to="tel:+4952144818690"
              external
              >+49 521 44818690</NuxtLink
            >
          </div>
        </div>
        <nav class="mt-vw-15-to-20 lg:mt-0" aria-label="Footer Navigation">
          <ul>
            <li>
              <NuxtLink
                class="inline-flex items-center gap-vw-1-to-3 transition-colors hover:text-green focus:outline-none focus-visible:outline-current"
                to="/impressum/"
                >Impressum<IconComponent
                  icon="arrow-right"
                  class="w-vw-2-to-3 flex-none"
                />
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                class="inline-flex items-center gap-vw-1-to-3 transition-colors hover:text-green focus:outline-none focus-visible:outline-current"
                to="/datenschutz/"
                >Datenschutz<IconComponent
                  icon="arrow-right"
                  class="w-vw-2-to-3 flex-none"
              /></NuxtLink>
            </li>
            <li>
              <NuxtLink
                class="inline-flex items-center gap-vw-1-to-3 transition-colors hover:text-green focus:outline-none focus-visible:outline-current"
                to="/kontakt/"
                >Kontakt<IconComponent
                  icon="arrow-right"
                  class="w-vw-2-to-3 flex-none"
              /></NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
</template>
